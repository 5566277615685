import React from 'react'
import Button from '../../../../components/Button'
import IconAdd from '../../../../images/IconAdd'
import BuildingTypeList from './buildingTypeList'
import classNames from 'classnames'
import PopupMenuLayout from '../../../../components/Dropdown/PopupMenuLayout'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface IBuildingTypeProps {
  editMode: boolean
  buildingTypes: GenPlanTypes.BuildingType[]
  setBuildingTypeId(id: string): void
}

const BuildingType: React.FC<IBuildingTypeProps> = ({ editMode, buildingTypes, setBuildingTypeId }) => {
  const [typesListShown, setTypesListShown] = React.useState<boolean>(false)
  const controlRef = React.useRef<HTMLDivElement>(null)
  return (
    <div ref={controlRef}>
      <Button
        className={classNames('home-page__add-object-btn', typesListShown && 'active')}
        disabled={!editMode}
        onClick={() => setTypesListShown(!typesListShown)}
        data-testid={'create-building-btn'}
      >
        <IconAdd />
      </Button>
      {typesListShown && (
        <PopupMenuLayout
          controlRef={controlRef}
          closeMenu={() => setTypesListShown(false)}
          className={'dropdown__options-list building-types-list'}
          data-testid={'building-type-list'}
        >
          <BuildingTypeList
            buildingTypes={buildingTypes}
            setBuildingTypeId={setBuildingTypeId}
            closeList={() => setTypesListShown(false)}
          />
        </PopupMenuLayout>
      )}
    </div>
  )
}

export default BuildingType
