import React from 'react'
import Input from '../../../../components/Input'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface ITitleRowProps {
  editMode: boolean
  title: string
  label: string
  updateBuilding(building: GenPlanTypes.BuildingUpdateParams): void
}

const TitleRow: React.FC<ITitleRowProps> = ({ editMode, title, label, updateBuilding }) => {
  const [titleValue, setTitleValue] = React.useState<string>('')
  const [labelValue, setLabelValue] = React.useState<string>('')

  React.useEffect(() => {
    setTitleValue(title)
  }, [title])

  React.useEffect(() => {
    setLabelValue(label)
  }, [label])

  const onTitleChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value)
  }

  const onLabelChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelValue(e.target.value)
  }

  const updateTitle = () => {
    setTitleValue(titleValue)
    updateBuilding({ name: titleValue })
  }

  const resetTitle = () => {
    setTitleValue(title)
  }

  const updateLabel = () => {
    setLabelValue(labelValue)
    updateBuilding({ label: labelValue })
  }

  const resetLabel = () => {
    setLabelValue(label)
  }

  return (
    <div className='object-panel__title-row'>
      <div className='title-row__title-wrapper'>
        <div className='object-panel__label'>Название сооружения</div>
        <Input
          value={titleValue}
          onChange={onTitleChangeHandler}
          onBlur={resetTitle}
          onEnter={updateTitle}
          disabled={!editMode}
          data-testid={'building-title'}
        />
      </div>
      <div className='title-row__number-wrapper'>
        <div className='object-panel__label'>Номер</div>
        <Input
          disabled={!editMode}
          value={labelValue}
          onChange={onLabelChangeHandler}
          onBlur={resetLabel}
          onEnter={updateLabel}
          data-testid={'building-label'}
        />
      </div>
    </div>
  )
}

export default TitleRow
