import * as React from 'react'
import classNames from 'classnames'
import { components } from '../../plan-design-component/src/generated/apiTypes'

interface IStatusProps {
  status: components['schemas']['TaskStatus']
}

const Status: React.FC<IStatusProps> = ({ status }) => {
  const getStatusCaption = (status: components['schemas']['TaskStatus']): string => {
    switch (status) {
      case 'PUBLISHED':
        return 'Опубликовано'
      case 'DRAFT':
        return 'Черновик'
      default:
        return ''
    }
  }
  return <div className={classNames('solution-status', status.toLowerCase())}>{getStatusCaption(status)}</div>
}

export default Status
