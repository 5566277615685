import React from 'react'
import Input from '../Input'

interface INumberInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  classNames?: string
  setValue(value: number): void
  signed?: boolean
}

const NumberInput: React.FC<INumberInputProps> = ({ value, classNames, disabled, setValue, signed, ...props }) => {
  const [valueState, setValueState] = React.useState<string>('')

  React.useEffect(() => {
    if (value !== undefined) setValueState(value.toString())
  }, [value])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (signed) {
      setValueState(
        e.target.value
          .replace(/,/, '.')
          .replace(/[^-?0-9.]/g, '')
          .replace(/(\..*)\./g, '$1'),
      )
    } else
      setValueState(
        e.target.value
          .replace(/,/, '.')
          .replace(/[^0-9.]/g, '')
          .replace(/(\..*)\./g, '$1'),
      )
  }

  const onBlurHandler = () => {
    /* const newValue = parseFloat(valueState!)
    if (newValue || newValue === 0) {
      setValue(newValue)
    }*/
    if (value !== undefined) setValueState(value.toString())
  }

  const onEnterHandler = () => {
    const newValue = parseFloat(valueState!)
    if (newValue || newValue === 0) {
      setValue(newValue)
    }
  }

  return (
    <Input
      {...props}
      value={valueState}
      onChange={onChangeHandler}
      classNames={classNames}
      disabled={disabled}
      onBlur={onBlurHandler}
      onEnter={onEnterHandler}
    />
  )
}

export default NumberInput
