import React from 'react'
import ShapeButton from './ShapeButton'
import GenPlanTypes from '@numdes/genplaneditor'

interface IShapeRowProps {
  editMode: boolean
  shape: 'RECT' | 'CIRCLE'
  length?: number
  radius?: number
  updateBuilding(building: GenPlanTypes.BuildingUpdateParams): void
}

const ShapeRow: React.FC<IShapeRowProps> = ({ editMode, shape, length, radius, updateBuilding }) => {
  const onCircleClick = () => {
    if (length) updateBuilding({ radius_m: length / 2, length_m: undefined, width_m: undefined })
  }

  const onRectClick = () => {
    if (radius) updateBuilding({ length_m: radius * 2, width_m: radius * 2, radius_m: undefined })
  }

  return (
    <div className='shape-row__container'>
      <div className='object-panel__label'>Форма</div>
      <div className='icon-buttons-row shape-row'>
        <ShapeButton editMode={editMode} shapeBtn={'RECT'} active={shape === 'RECT'} onClick={onRectClick} />
        <ShapeButton editMode={editMode} shapeBtn={'CIRCLE'} active={shape === 'CIRCLE'} onClick={onCircleClick} />
      </div>
    </div>
  )
}

export default ShapeRow
