import React from 'react'

import './style.scss'
import Button from '../../../../components/Button'

interface IMethodErrorWindowProps {
  errorMessage: string
  clearErrorMessage(): void
}

const MethodErrorWindow: React.FC<IMethodErrorWindowProps> = ({ errorMessage, clearErrorMessage }) => {
  return (
    <div className='method-window__background'>
      <div className='method-window__container'>
        <div className='method-window__wrapper'>
          <div className='method-window__title'>Ошибка</div>
          <div className='method-window__method-name'>{errorMessage}</div>
          <Button mode={'secondary'} onClick={() => clearErrorMessage()}>
            Закрыть
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MethodErrorWindow
