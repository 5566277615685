import React from 'react'
import classNames from 'classnames'
import IconRectangle from '../../../../images/IconRectangle'
import IconCircle from '../../../../images/IconCircle'

interface IShapeButtonProps {
  editMode: boolean
  shapeBtn: 'CIRCLE' | 'RECT'
  active: boolean
  onClick(): void
}

const ShapeButton: React.FC<IShapeButtonProps> = ({ editMode, shapeBtn, active, onClick }) => {
  return (
    <button className={classNames('icon-buttons-row-btn', { active })} onClick={onClick} disabled={!editMode}>
      {shapeBtn === 'RECT' ? <IconRectangle /> : <IconCircle />}
    </button>
  )
}

export default ShapeButton
