import React from 'react'
import Checkbox from '../../../../components/Checkbox'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface ISizeRowProps {
  editMode: boolean
  isFixed?: boolean
  updateBuilding(building: GenPlanTypes.BuildingUpdateParams): void
}

const SizeRow: React.FC<ISizeRowProps> = ({ editMode, isFixed, updateBuilding }) => {
  const [fixPositionValue, setFixPositionValue] = React.useState<boolean>(false)

  React.useEffect(() => {
    setFixPositionValue(isFixed!)
  }, [isFixed])

  const setFixPosition = (value: boolean) => {
    setFixPositionValue(value)
    updateBuilding({ is_fixed: value })
  }

  return (
    <div className='object-panel__fix-position-row'>
      <Checkbox
        checkedValue={fixPositionValue}
        setCheckedValue={setFixPosition}
        disabled={!editMode}
        data-testid={'building-fix-position'}
      >
        Зафиксировать положение для алгоритмов
      </Checkbox>
    </div>
  )
}

export default SizeRow
