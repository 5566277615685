import React from 'react'
import './style.scss'
import AngleRow from './AngleRow'
import TitleRow from './TitleRow'
import SizeRow from './SizeRow'
import CoordinatesRow from './CoordinatesRow'
import Connections from './Connections/connections'
import FixPositionRow from './FixPositionRow'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface IObjectPanelProps {
  object: GenPlanTypes.ExtendedBuilding | undefined
  editMode: boolean
  setDeltaCoordinates(coordinates: { x: number; y: number }): void
  coordinateError: boolean
  setCoordinateError(error: boolean): void
  updateBuilding(building: GenPlanTypes.BuildingUpdateParams): void
  panelRef?: React.RefObject<HTMLDivElement>
  connectionTypes: GenPlanTypes.ConnectionType[]
  connectionPointsOptions: GenPlanTypes.ConnectionPointWithConnectionPointsToConnect[]
  setCreateConnection(connection: { id: string; data: GenPlanTypes.CreateConnectionData }): void
  setUpdateConnection(connection: { id: string; data: GenPlanTypes.UpdateConnectionData }): void
  setDeleteConnection(connection: { id: string }): void
  setConnectionIdToShow(id: string): void
}

const ObjectPanel: React.FC<IObjectPanelProps> = ({
  object,
  editMode,
  setDeltaCoordinates,
  coordinateError,
  setCoordinateError,
  updateBuilding,
  panelRef,
  connectionTypes,
  connectionPointsOptions,
  setCreateConnection,
  setUpdateConnection,
  setDeleteConnection,
  setConnectionIdToShow,
}) => {
  return (
    <>
      <div className='home-page__objects-list-top-row object-panel-top-row'>
        <div className='home-page__objects-list-title'>Параметры сооружения</div>
      </div>
      {object ? (
        <>
          <div className='object-panel__type-row' data-testid={'building-type'}>
            {object.building_type.name}
          </div>
          <div className='object-panel__separator' />
          <TitleRow title={object.name} label={object.label} editMode={editMode} updateBuilding={updateBuilding} />
          <div className='object-panel__separator' />
          <SizeRow
            editMode={editMode}
            length={object.length_m}
            width={object.width_m}
            radius={object.radius_m}
            updateBuilding={updateBuilding}
          />
          <div className='object-panel__separator' />
          <CoordinatesRow
            editMode={editMode}
            center_point_m={object.center_point_m}
            width={object.width_m || object.radius_m! * 2}
            length={object.length_m || object.radius_m! * 2}
            angle_deg={object.angle_deg}
            error={coordinateError}
            setError={setCoordinateError}
            setDeltaCoordinates={setDeltaCoordinates}
          />
          <AngleRow angle={object?.angle_deg} />
          <FixPositionRow editMode={editMode} isFixed={object?.is_fixed} updateBuilding={updateBuilding} />
          <div className='object-panel__separator' />
          <Connections
            connectionPoints={object.connection_points}
            connectionTypes={connectionTypes}
            connectionPointsOptions={connectionPointsOptions}
            panelRef={panelRef}
            editMode={editMode}
            setCreateConnection={setCreateConnection}
            setUpdateConnection={setUpdateConnection}
            setDeleteConnection={setDeleteConnection}
            setConnectionIdToShow={setConnectionIdToShow}
          />
        </>
      ) : (
        <div className='object-panel__empty-message'>{'Объект не выбран'}</div>
      )}
    </>
  )
}

export default ObjectPanel
