import * as React from 'react'

function IconLightTheme(props: any) {
  return (
    <svg className={props.className} width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.46444 3.75736C4.26918 3.5621 3.9526 3.5621 3.75734 3.75736C3.56207 3.95262 3.56207 4.2692 3.75734 4.46447L4.46444 5.17157C4.6597 5.36684 4.97629 5.36684 5.17155 5.17157C5.36681 4.97631 5.36681 4.65973 5.17155 4.46447L4.46444 3.75736Z'
        fill='#405264'
      />
      <path
        d='M2.5 7.5C2.22386 7.5 2 7.72386 2 8C2 8.27614 2.22386 8.5 2.5 8.5H3.49991C3.77605 8.5 3.99991 8.27614 3.99991 8C3.99991 7.72386 3.77605 7.5 3.49991 7.5H2.5Z'
        fill='#405264'
      />
      <path
        d='M8.5 2.5C8.5 2.22386 8.27614 2 8 2C7.72386 2 7.5 2.22386 7.5 2.5L7.5 3.5C7.5 3.77614 7.72386 4 8 4C8.27614 4 8.5 3.77614 8.5 3.5L8.5 2.5Z'
        fill='#405264'
      />
      <path
        d='M3.75736 11.5356C3.5621 11.7308 3.5621 12.0474 3.75736 12.2427C3.95263 12.4379 4.26921 12.4379 4.46447 12.2427L5.17158 11.5356C5.36684 11.3403 5.36684 11.0237 5.17158 10.8285C4.97632 10.6332 4.65973 10.6332 4.46447 10.8285L3.75736 11.5356Z'
        fill='#405264'
      />
      <path
        d='M10.8284 4.46454C10.6331 4.65981 10.6331 4.97639 10.8284 5.17165C11.0236 5.36691 11.3402 5.36691 11.5355 5.17165L12.2426 4.46454C12.4379 4.26928 12.4379 3.9527 12.2426 3.75744C12.0473 3.56218 11.7308 3.56218 11.5355 3.75744L10.8284 4.46454Z'
        fill='#405264'
      />
      <path
        d='M8.5 12.5C8.5 12.2239 8.27614 12 8 12C7.72386 12 7.5 12.2239 7.5 12.5L7.5 13.5C7.5 13.7761 7.72386 14 8 14C8.27614 14 8.5 13.7761 8.5 13.5L8.5 12.5Z'
        fill='#405264'
      />
      <path
        d='M12.5 7.5C12.2239 7.5 12 7.72386 12 8C12 8.27614 12.2239 8.5 12.5 8.5H13.5001C13.7762 8.5 14.0001 8.27614 14.0001 8C14.0001 7.72386 13.7762 7.5 13.5001 7.5H12.5Z'
        fill='#405264'
      />
      <path
        d='M11.5355 10.8284C11.3402 10.6332 11.0237 10.6332 10.8284 10.8284C10.6331 11.0237 10.6331 11.3403 10.8284 11.5355L11.5355 12.2426C11.7308 12.4379 12.0474 12.4379 12.2426 12.2426C12.4379 12.0474 12.4379 11.7308 12.2426 11.5355L11.5355 10.8284Z'
        fill='#405264'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00005 5.5C6.61933 5.5 5.50005 6.61929 5.50005 8C5.50005 9.38071 6.61933 10.5 8.00005 10.5C9.38076 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38076 5.5 8.00005 5.5Z'
        fill='#405264'
      />
    </svg>
  )
}

export default IconLightTheme
