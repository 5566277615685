import React, { PropsWithChildren, ReactElement } from 'react'
import classNames from 'classnames'

interface IOptionProps<OptionType> {
  item: OptionType
  displayedField: string
  setSelectedValue?(value: OptionType): void
  closeOptionsList(): void
}

const Option = <OptionType,>({
  item,
  displayedField,
  setSelectedValue,
  closeOptionsList,
}: PropsWithChildren<IOptionProps<OptionType>>): ReactElement => {
  const optionRef = React.useRef<HTMLDivElement>(null)
  const valueRef = React.useRef<HTMLSpanElement>(null)
  const [valueClassName, setValueClassName] = React.useState<string>()

  React.useEffect(() => {
    if (optionRef.current && valueRef.current) {
      if (
        valueRef.current.offsetWidth >
        optionRef.current.clientWidth -
          parseInt(getComputedStyle(optionRef.current).paddingLeft) -
          parseInt(getComputedStyle(optionRef.current).paddingRight)
      ) {
        setValueClassName('animate')
      } else {
        setValueClassName(undefined)
      }
    }
  }, [optionRef.current, valueRef.current])

  const onOptionClick = () => {
    if (setSelectedValue) setSelectedValue(item)
    closeOptionsList()
  }

  return (
    <div className='dropdown__option' onClick={onOptionClick} ref={optionRef}>
      <span className={classNames('dropdown__option-value', valueClassName)} ref={valueRef}>
        {(item as any)[displayedField] || 'Остутствует поле'}
      </span>
    </div>
  )
}

export default Option
