import React from 'react'
import Input from '../../../../components/Input'

interface IAngleRowProps {
  angle: number
}

const AngleRow: React.FC<IAngleRowProps> = ({ angle }) => {
  return (
    <>
      <div className='object-panel__label'>Поворот</div>
      <div className='object-panel__angle-row'>
        <div className='object-panel__short-input-wrapper'>
          <Input value={`${angle}°`} data-testid={'building-angle'} disabled />
        </div>
      </div>
    </>
  )
}

export default AngleRow
