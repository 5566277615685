import React from 'react'

import './style.scss'
import BuildingTypeItem from './buildingTypeItem'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface IBuildingTypeListProps {
  buildingTypes: GenPlanTypes.BuildingType[]
  setBuildingTypeId(id: string): void
  closeList(): void
}

const BuildingTypeList: React.FC<IBuildingTypeListProps> = ({ buildingTypes, setBuildingTypeId, closeList }) => {
  return (
    <>
      {buildingTypes.map((item) => (
        <BuildingTypeItem key={item.id} item={item} setBuildingTypeId={setBuildingTypeId} closeList={closeList} />
      ))}
    </>
  )
}

export default BuildingTypeList
