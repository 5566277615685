import { Package } from '@numdes/genplaneditor'
//import { Package } from '../../plan-design-component/src'
import React from 'react'
import '../style.scss'
import Layout from '../../components/Layout/layout'
import type * as GenPlanTypes from '@numdes/genplaneditor'
import { getTaskId } from './constants/project'
import Properties from './components/ObjectProperties/properties'
import ConnectionPoints from './components/ConnectionPoints/points'
import { useLocation, useNavigate } from 'react-router-dom'

const SolutionPage: React.FC = () => {
  const [object, setObject] = React.useState<GenPlanTypes.ExtendedBuilding>()
  const [activeObjectId, setActiveObjectId] = React.useState<string>()
  const [glossary, setGlossary] = React.useState<GenPlanTypes.GlossaryType>()
  const [buildingTypeIdToCreate, setBuildingTypeIdToCreate] = React.useState<string>()
  const [editMode, setEditMode] = React.useState<{
    edit: boolean
    status: GenPlanTypes.FinishManualModeCommandStatus
  }>({ edit: false, status: 'CANCEL' })
  const [newBuildingParams, setNewBuildingParams] = React.useState<GenPlanTypes.BuildingUpdateParams>({})
  const [actionId, setActionId] = React.useState<string>('')
  const [taskIdToPublish, setTaskIdToPublish] = React.useState<string>()
  const [taskId, setTaskId] = React.useState<string | undefined>()
  const [task, setTask] = React.useState<GenPlanTypes.ExtendedTaskType>()
  const objectPanelRef = React.useRef<HTMLDivElement>(null)
  const leftPanelRef = React.useRef<HTMLDivElement>(null)
  const [blobObj, setBlobObj] = React.useState<string>()
  const [darkTheme, setDarkTheme] = React.useState<boolean>(false)
  const [createConnectionPoint, setCreateConnectionPoint] = React.useState<{
    data: GenPlanTypes.CreateConnectionPoint
  }>()
  const [updateConnectionPoint, setUpdateConnectionPoint] = React.useState<{
    id: string
    data: GenPlanTypes.UpdateConnectionPoint
  }>()
  const [deleteConnectionPoint, setDeleteConnectionPoint] = React.useState<{
    id: string
  }>()
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (location.state?.id) setTaskId(location.state.id)
    else setTaskId(getTaskId())
  }, [])

  React.useEffect(() => {
    if (blobObj) {
      const getFileName: { [name: string]: string } = {
        '1': 'drawing.dxf',
        '2': 'volume_values_report.xlsx',
        '3': 'project_distances_report.xlsx',
        '4': 'communication_report.xlsx',
      }
      const url = window.URL.createObjectURL(new Blob([blobObj]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', getFileName[actionId])
      document.body.appendChild(link)
      link.click()
      setBlobObj(undefined)
    }
    setActionId('')
  }, [blobObj])

  React.useEffect(() => {
    if (task?.type && task?.type !== 'BTS') navigate('/', { state: { id: taskId } })
  }, [task?.type])

  return (
    <div className='home-page__container'>
      <Layout
        editMode={editMode.edit}
        setEditMode={setEditMode}
        setActionId={setActionId}
        taskId={taskId}
        setTaskId={setTaskId}
        darkTheme={darkTheme}
        setDarkTheme={setDarkTheme}
        taskType={'SOLUTION'}
        task={task}
        setTaskIdToPublish={setTaskIdToPublish}
      >
        <div className='home-page__content'>
          <div className='home-page__side-panel left' ref={leftPanelRef}>
            <div className='home-page__objects-list solution-property'>
              <div className='home-page__objects-list-top-row'>
                <div className='home-page__objects-list-title'>Свойства сооружения</div>
              </div>
              <Properties
                updateBuilding={setNewBuildingParams}
                editMode={editMode.edit}
                object={object}
                panelRef={leftPanelRef}
                taskName={task?.name || ''}
                objectTypeId={task?.object_type_id || ''}
                glossary={glossary}
              />
            </div>
          </div>
          <div className='home-page__graphic-panel'>
            <Package
              baseApiURL={process.env.REACT_APP_API_URL}
              activeObjectId={activeObjectId}
              setActiveObjectId={setActiveObjectId}
              editMode={editMode}
              setActiveObject={setObject}
              setGlossary={setGlossary}
              newBuildingParams={newBuildingParams}
              buildingTypeIdToCreate={buildingTypeIdToCreate}
              resetCreateMode={() => setBuildingTypeIdToCreate(undefined)}
              taskId={taskId}
              setEditMode={setEditMode}
              actionId={actionId}
              resetActionId={() => setActionId('')}
              setBlobObj={setBlobObj}
              darkTheme={darkTheme}
              setTask={setTask}
              setTaskId={setTaskId}
              taskIdToPublish={taskIdToPublish}
              createConnectionPoint={createConnectionPoint}
              updateConnectionPoint={updateConnectionPoint}
              deleteConnectionPoint={deleteConnectionPoint}
            />
          </div>
          <div className='home-page__side-panel right' ref={objectPanelRef}>
            <ConnectionPoints
              editMode={editMode.edit}
              object={object}
              panelRef={objectPanelRef}
              glossary={glossary}
              setCreateConnectionPoint={setCreateConnectionPoint}
              setDeleteConnectionPoint={setDeleteConnectionPoint}
              setUpdateConnectionPoint={setUpdateConnectionPoint}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default SolutionPage
