import * as React from 'react'

function IconEdit() {
  return (
    <svg className='svg' width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14 5L11 2L9.58578 3.41422L12.5858 6.41422L14 5Z' fill='#2C2C2C' />
      <path d='M11.8787 7.12132L8.87868 4.12132L2 11V14H5L11.8787 7.12132Z' fill='#2C2C2C' />
    </svg>
  )
}

export default IconEdit
