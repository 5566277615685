import React from 'react'
import classNames from 'classnames'
import IconSquareConnPoints from '../../../../images/IconSquareConnPoints'
import IconCircleConnPoints from '../../../../images/IconCircleConnPoints'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface IPointPositionButtonProps {
  editMode: boolean
  shape: 'CIRCLE' | 'RECT'
  active: boolean
  btnId: string
  connectionPoint: GenPlanTypes.ExtendedConnectionPoint
  setUpdateConnectionPoint(connectionPoint: { id: string; data: GenPlanTypes.UpdateConnectionPoint }): void
}

const PointPositionButton: React.FC<IPointPositionButtonProps> = ({
  editMode,
  shape,
  active,
  connectionPoint,
  setUpdateConnectionPoint,
  btnId,
}) => {
  const onClickHandler = () => {
    setUpdateConnectionPoint({
      id: connectionPoint.id,
      data: {
        border: btnId as any,
        offset: connectionPoint.offset,
      },
    })
  }

  return (
    <button
      className={classNames('icon-buttons-row-btn position-btn', { active })}
      onClick={onClickHandler}
      disabled={!editMode}
    >
      {shape === 'RECT' ? <IconSquareConnPoints /> : <IconCircleConnPoints />}
    </button>
  )
}

export default PointPositionButton
