import React from 'react'
import './style.scss'
import Dropdown from '../../../../../components/Dropdown/Dropdown'
import Button from '../../../../../components/Button'
import IconShow from '../../../../../images/IconShow'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface IConnectionPointProps {
  index: number
  connectionPoint: GenPlanTypes.ExtendedConnectionPoint
  connectionTypes: GenPlanTypes.ConnectionType[]
  optionsToConnect?: GenPlanTypes.ConnectionPointToConnect[]
  panelRef?: React.RefObject<HTMLDivElement>
  editMode: boolean
  setCreateConnection(connection: { id: string; data: GenPlanTypes.CreateConnectionData }): void
  setUpdateConnection(connection: { id: string; data: GenPlanTypes.UpdateConnectionData }): void
  setDeleteConnection(connection: { id: string }): void
  setConnectionIdToShow(id: string): void
}

type ConnectionLocationType = {
  id: GenPlanTypes.LocationType
  name: string
}

const connectionLocationTypeOptions: ConnectionLocationType[] = [
  { id: 'UNDERGROUND', name: 'Подземные' },
  { id: 'OVERGROUND', name: 'Надземные' },
]

const ConnectionPoint: React.FC<IConnectionPointProps> = ({
  index,
  connectionPoint,
  connectionTypes,
  optionsToConnect,
  panelRef,
  editMode,
  setCreateConnection,
  setUpdateConnection,
  setDeleteConnection,
  setConnectionIdToShow,
}) => {
  const [connectionPointValue, setConnectionPointValue] = React.useState<{
    id: string
    name: string
  }>()
  const [connectionTypeValue, setConnectionTypeValue] = React.useState<GenPlanTypes.ConnectionType>()
  const [connectionLocationTypeValue, setConnectionLocationTypeValue] = React.useState<ConnectionLocationType>()

  React.useEffect(() => {
    if (!connectionPoint.connection) {
      setConnectionPointValue(undefined)
      setConnectionTypeValue(undefined)
      setConnectionLocationTypeValue(undefined)
    }
  }, [connectionPoint.connection])

  React.useEffect(() => {
    if (connectionPoint.connection) {
      setConnectionPointValue({
        id: connectionPoint.connection.corresponding_connection_point.id,
        name: `${connectionPoint.connection.corresponding_connection_point.building.name}. ${
          connectionPoint.connection.corresponding_connection_point.description
        }. ${connectionPoint.connection.corresponding_connection_point.direction === 'INPUT' ? 'Вход' : 'Выход'}`,
      })
    }
  }, [connectionPoint.connection?.corresponding_connection_point])

  React.useEffect(() => {
    if (connectionPoint.connection) {
      setConnectionLocationTypeValue(
        connectionLocationTypeOptions.find((item) => item.id === connectionPoint.connection?.connection_location_type),
      )
    }
  }, [connectionPoint.connection?.connection_location_type])

  React.useEffect(() => {
    if (connectionPoint.connection) {
      setConnectionTypeValue(
        connectionTypes.find((item) => item.name === connectionPoint.connection?.connection_type_name),
      )
    }
  }, [connectionPoint.connection?.connection_type_name])

  const onChangeConnectionPointValue = (value: { id: string; name: string }) => {
    if (!connectionPointValue) {
      setCreateConnection({
        id: connectionPoint.id,
        data: {
          corresponding_connection_point_id: value.id,
          connection_location_type: connectionLocationTypeValue?.id || connectionLocationTypeOptions[1].id,
          connection_type_name:
            connectionTypeValue?.name || connectionTypes.find((item) => item.name === 'Дорога')?.name!,
        },
      })
    } else {
      value.id &&
        setUpdateConnection({
          id: connectionPoint.id,
          data: {
            corresponding_connection_point_id: value.id,
          },
        })
      !value.id &&
        setDeleteConnection({
          id: connectionPoint.id,
        })
    }
    value.id ? setConnectionPointValue(value) : setConnectionPointValue(undefined)
  }

  const onChangeConnectionLocationTypeValue = (value: ConnectionLocationType) => {
    if (connectionPointValue)
      setUpdateConnection({
        id: connectionPoint.id,
        data: {
          connection_location_type: value.id,
        },
      })
    setConnectionLocationTypeValue(value)
  }

  const onChangeConnectionTypeValue = (value: GenPlanTypes.ConnectionType) => {
    if (connectionPointValue)
      setUpdateConnection({
        id: connectionPoint.id,
        data: {
          connection_type_name: value.name,
        },
      })
    setConnectionTypeValue(value)
  }

  const displayedOptions = React.useMemo(
    () =>
      optionsToConnect?.map((option) => {
        return {
          id: option.id,
          name: `${option.building_name}. ${option.description}. ${option.direction === 'INPUT' ? 'Вход' : 'Выход'}`,
        }
      }) || [],
    [optionsToConnect],
  )

  return (
    <div className='connection-point__container'>
      <div className='connection-point__top-row'>
        <div className='connection-point__index'>{index}</div>
        <span className='connection-point__type' data-testid={'building-cp-desc'}>
          {`${connectionPoint.description}. ${connectionPoint.direction === 'INPUT' ? 'Вход' : 'Выход'}`}
        </span>
      </div>
      <div className='connection-point__row'>
        <div className='object-panel__label'>Подключение</div>
        <div className='connection-point__connection-row'>
          <div className='connection-point__connection-row-dropdown'>
            <Dropdown
              selectedValue={connectionPointValue}
              setSelectedValue={onChangeConnectionPointValue}
              options={[
                ...displayedOptions,
                ...(connectionPointValue !== undefined
                  ? [
                      {
                        id: null,
                        name: 'Не задано',
                      },
                    ]
                  : []),
              ]}
              displayedField='name'
              panelRef={panelRef}
              disabled={!editMode}
            />
          </div>
          <Button
            className='connection-point__show-btn'
            mode='secondary'
            disabled={!connectionPoint.connection?.has_communication}
            onMouseEnter={() =>
              connectionPointValue !== undefined && setConnectionIdToShow(connectionPoint.connection?.id!)
            }
            onMouseLeave={() => setConnectionIdToShow('')}
          >
            <IconShow />
          </Button>
        </div>
      </div>
      <div className='connection-point__row connection-point__connection-row-types'>
        <div className='connection-point__connection-method'>
          <div className='object-panel__label'>Способ прокл.</div>
          <Dropdown
            selectedValue={connectionLocationTypeValue || connectionLocationTypeOptions[1]}
            setSelectedValue={onChangeConnectionLocationTypeValue}
            options={connectionLocationTypeOptions}
            displayedField='name'
            panelRef={panelRef}
            disabled={!editMode}
          />
        </div>
        <div className='connection-point__connection-type'>
          <div className='object-panel__label'>Тип коммуник.</div>
          <Dropdown
            selectedValue={connectionTypeValue || connectionTypes.find((item) => item.name === 'Дорога')}
            options={connectionTypes}
            displayedField='name'
            panelRef={panelRef}
            disabled={!editMode}
            setSelectedValue={onChangeConnectionTypeValue}
          />
        </div>
      </div>
    </div>
  )
}

export default ConnectionPoint
