import React from 'react'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface ITypeRowProps {
  editMode: boolean
  type: string
  panelRef?: React.RefObject<HTMLDivElement>
  glossary: GenPlanTypes.GlossaryType | undefined
}

const TypeRow: React.FC<ITypeRowProps> = ({ type, panelRef, glossary }) => {
  const [typeValue, setTypeValue] = React.useState<GenPlanTypes.BuildingType>()

  React.useEffect(() => {
    setTypeValue(glossary?.building_types.find((item) => item.id === type))
  }, [type])

  const onTypeChangeHandler = (value: GenPlanTypes.BuildingType) => {
    setTypeValue(value)
  }

  return (
    <div className={'property__item'}>
      <div className='object-panel__label'>Тип</div>
      <Dropdown
        options={glossary?.building_types || []}
        displayedField='name'
        disabled={true}
        panelRef={panelRef}
        selectedValue={typeValue}
        setSelectedValue={onTypeChangeHandler}
      />
    </div>
  )
}

export default TypeRow
