import React from 'react'
import classNames from 'classnames'

interface IObjectItemProps {
  id: string
  name: string
  label: string
  setActiveObjectId(id: string): void
  activeObjectId: string | undefined
}

const ObjectItem: React.FC<IObjectItemProps> = ({ name, label, id, setActiveObjectId, activeObjectId }) => {
  return (
    <div
      className={classNames('home-page__object-item', activeObjectId === id && 'selected')}
      onClick={() => {
        setActiveObjectId(id)
      }}
      data-testid={'building-list-item'}
    >
      {`${label} ${name}`}
    </div>
  )
}

export default ObjectItem
