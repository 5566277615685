import React from 'react'
import Button from '../../../../components/Button'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface IAlgorithmPanelProps {
  editMode: boolean
  methodsOptions: GenPlanTypes.CalculationMethod[]
  panelRef?: React.RefObject<HTMLDivElement>
  setMethod(method: GenPlanTypes.CalculationMethod): void
}

const AlgorithmPanel: React.FC<IAlgorithmPanelProps> = ({ editMode, methodsOptions, panelRef, setMethod }) => {
  const [methodValue, setMethodValue] = React.useState<GenPlanTypes.CalculationMethod>()

  const onChangeTaskTypeValue = (value: GenPlanTypes.CalculationMethod) => {
    setMethodValue(value)
  }

  const onApplyClick = () => {
    if (methodValue) setMethod(methodValue)
  }

  return (
    <>
      <div className='home-page__objects-list-title algorithm-title'>Автоматическое построение</div>
      <div className='object-panel__label'>Алгоритм</div>
      <div className='home-page__algorithm-controls'>
        <div className='home-page__algorithm-select'>
          <Dropdown
            displayedField='description'
            setSelectedValue={onChangeTaskTypeValue}
            selectedValue={methodValue}
            options={methodsOptions}
            panelRef={panelRef}
            disabled={!editMode}
          />
        </div>
        <Button disabled={!editMode} onClick={onApplyClick}>
          Применить
        </Button>
      </div>
    </>
  )
}

export default AlgorithmPanel
