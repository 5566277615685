import * as React from 'react'

function IconShow() {
  return (
    <svg className='svg' width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.6668 7.99998C12.8888 11.1113 10.6668 12.6666 8.00016 12.6666C5.3335 12.6666 3.1115 11.1113 1.3335 7.99998C3.1115 4.88865 5.3335 3.33331 8.00016 3.33331C10.6668 3.33331 12.8888 4.88865 14.6668 7.99998Z'
        stroke='#838383'
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M7.99984 9.33335C8.73622 9.33335 9.33317 8.7364 9.33317 8.00002C9.33317 7.26364 8.73622 6.66669 7.99984 6.66669C7.26346 6.66669 6.6665 7.26364 6.6665 8.00002C6.6665 8.7364 7.26346 9.33335 7.99984 9.33335Z'
        fill='none'
        stroke='#838383'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconShow
