import * as React from 'react'

function IconCircleConnPoints(props: any) {
  return (
    <svg
      className={props.className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke='#8DBEDD' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 1C6 2.10457 6.89543 3 8 3C9.10457 3 10 2.10457 10 1H6Z'
        fill='#0078D2'
      />
    </svg>
  )
}

export default IconCircleConnPoints
