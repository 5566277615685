import * as React from 'react'

function IconDarkTheme(props: any) {
  return (
    <svg className={props.className} width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.7371 9.60413C12.2118 9.85781 11.6225 10 11 10C8.79086 10 7 8.20914 7 6C7 4.81152 7.51832 3.7441 8.34125 3.01146C8.22848 3.00386 8.11469 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.2003 13 12.0687 11.5787 12.7371 9.60413Z'
        fill='#405264'
      />
    </svg>
  )
}

export default IconDarkTheme
