import React from 'react'
import Button from '../../../../../components/Button'
import IconEdit from '../../../../../images/IconEdit'
import './style.scss'
import ConnectionPoint from './connectionPoint'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface IConnectionProps {
  connectionPoints: GenPlanTypes.ExtendedConnectionPoint[]
  connectionTypes: GenPlanTypes.ConnectionType[]
  connectionPointsOptions: GenPlanTypes.ConnectionPointWithConnectionPointsToConnect[]
  panelRef?: React.RefObject<HTMLDivElement>
  editMode: boolean
  setCreateConnection(connection: { id: string; data: GenPlanTypes.CreateConnectionData }): void
  setUpdateConnection(connection: { id: string; data: GenPlanTypes.UpdateConnectionData }): void
  setDeleteConnection(connection: { id: string }): void
  setConnectionIdToShow(id: string): void
}

const Connections: React.FC<IConnectionProps> = ({
  connectionPoints,
  connectionTypes,
  connectionPointsOptions,
  panelRef,
  editMode,
  setCreateConnection,
  setUpdateConnection,
  setDeleteConnection,
  setConnectionIdToShow,
}) => {
  return (
    <div className='connections__container'>
      <div className='connections__top-row'>
        <span className='connections__top-row-title'>Подключения</span>
        <Button className='connections__edit-btn' mode='secondary' disabled>
          <IconEdit />
        </Button>
      </div>
      {connectionPoints.map((item, index) => (
        <ConnectionPoint
          key={item.id}
          index={index + 1}
          connectionPoint={item}
          connectionTypes={connectionTypes}
          optionsToConnect={
            connectionPointsOptions.find((options) => options.id === item.id)?.connection_points_to_connect
          }
          panelRef={panelRef}
          editMode={editMode}
          setCreateConnection={setCreateConnection}
          setUpdateConnection={setUpdateConnection}
          setDeleteConnection={setDeleteConnection}
          setConnectionIdToShow={setConnectionIdToShow}
        />
      ))}
    </div>
  )
}

export default Connections
