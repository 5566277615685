import React, { PropsWithChildren, ReactElement } from 'react'

import './style.scss'
import IconArrayDown from './IconArrayDown'
import classNames from 'classnames'
import PopupMenuLayout from './PopupMenuLayout'
import Option from './Option'

interface IDropdownProps<OptionType> {
  options: OptionType[]
  selectedValue?: OptionType
  setSelectedValue?(value: OptionType): void
  displayedField: string
  valuePlaceholder?: string
  panelRef?: React.RefObject<HTMLDivElement>
  disabled?: boolean
}

const Dropdown = <OptionType,>({
  options,
  selectedValue,
  displayedField,
  valuePlaceholder,
  setSelectedValue,
  panelRef,
  disabled,
}: PropsWithChildren<IDropdownProps<OptionType>>): ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false)
  const controlRef = React.useRef<HTMLDivElement>(null)
  const [menuBottom, setMenuBottom] = React.useState<number>()
  const [panelBottom, setPanelBottom] = React.useState<number>()
  const [menuRef, setMenuRef] = React.useState<React.RefObject<HTMLDivElement>>()
  const valueRef = React.useRef<HTMLSpanElement>(null)
  const iconRef = React.useRef<any>(null)
  const [valueClassName, setValueClassName] = React.useState<string>()

  React.useEffect(() => {
    if (controlRef.current && valueRef.current) {
      if (
        valueRef.current.offsetWidth >
        controlRef.current.clientWidth -
          parseInt(getComputedStyle(controlRef.current).paddingLeft) -
          parseInt(getComputedStyle(controlRef.current).paddingRight) -
          parseInt(getComputedStyle(iconRef?.current).width)
      ) {
        setValueClassName('animate')
      } else {
        setValueClassName(undefined)
      }
    }
  }, [controlRef.current, valueRef.current, valueRef.current?.offsetWidth])

  React.useEffect(() => {
    setMenuBottom(menuRef?.current?.getBoundingClientRect().bottom)
  }, [menuRef?.current, open])

  React.useEffect(() => {
    setPanelBottom(panelRef?.current?.getBoundingClientRect().bottom)
  }, [panelRef?.current])
  return (
    <div className='dropdown__container' ref={controlRef}>
      <button className='dropdown__value-container' onClick={() => setOpen(!open)} disabled={disabled}>
        <div className='dropdown__value-wrapper'>
          <span className={classNames('dropdown__value', valueClassName)} ref={valueRef}>
            {selectedValue
              ? (selectedValue as any)[displayedField] || 'Отсутсвует поле'
              : valuePlaceholder || 'Не задано'}{' '}
          </span>
        </div>
        <div className={classNames('dropdown__indicator', { open })} ref={iconRef}>
          <IconArrayDown />
        </div>
      </button>
      {open && (
        <PopupMenuLayout
          controlRef={controlRef}
          closeMenu={() => setOpen(false)}
          className={classNames(
            menuBottom !== undefined ? 'dropdown__options-list' : 'dropdown__options-list no-display',
            menuBottom! > panelBottom! && 'open-up',
          )}
          setMenuRef={setMenuRef}
        >
          {options.map((item, index) => (
            <Option
              key={index}
              item={item}
              displayedField={displayedField}
              setSelectedValue={setSelectedValue}
              closeOptionsList={() => setOpen(false)}
            />
          ))}
          {options.length === 0 && <div className='dropdown__empty-options'>Список опций пуст</div>}
        </PopupMenuLayout>
      )}
    </div>
  )
}

export default Dropdown
