import React from 'react'
import NumberInput from '../../../../components/NumberInput/numberInput'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface ICoordinatesRowProps {
  editMode: boolean
  center_point_m: GenPlanTypes.GeoPoint
  width?: number
  length?: number
  angle_deg: number
  error: boolean
  setError(error: boolean): void
  setDeltaCoordinates(coordinates: { x: number; y: number }): void
}

const CoordinatesRow: React.FC<ICoordinatesRowProps> = ({
  editMode,
  center_point_m,
  width,
  length,
  angle_deg,
  error,
  setError,
  setDeltaCoordinates,
}) => {
  const [xCoordinate, setXCoordinate] = React.useState<number>(0)
  const [yCoordinate, setYCoordinate] = React.useState<number>(0)

  const initCoordinates = () => {
    setXCoordinate(
      parseFloat((center_point_m.coordinates[0] as number).toFixed(2)) -
        (angle_deg % 180 === 0 ? length! / 2 : width! / 2),
    )
    setYCoordinate(
      parseFloat((center_point_m.coordinates[1] as number).toFixed(2)) -
        (angle_deg % 180 === 0 ? width! / 2 : length! / 2),
    )
  }

  React.useEffect(() => {
    if (center_point_m && width && length) initCoordinates()
  }, [center_point_m, width, length])

  React.useEffect(() => {
    if (error) {
      initCoordinates()
      setError(false)
    }
  }, [error])

  const setCoordinatesX = (value: number) => {
    setDeltaCoordinates({ x: value - xCoordinate!, y: 0 })
    setXCoordinate(value)
  }

  const setCoordinatesY = (value: number) => {
    setDeltaCoordinates({ x: 0, y: value - yCoordinate! })
    setYCoordinate(value)
  }

  return (
    <>
      <div className='object-panel__coordinates-row'>
        <div className='object-panel__short-input-wrapper'>
          <div className='object-panel__label'>X</div>
          <div className='object-panel__input-wrapper'>
            <NumberInput
              value={xCoordinate?.toFixed(2)}
              disabled={!editMode}
              classNames={'with-unit'}
              setValue={setCoordinatesX}
              data-testid={'building-x-coordinate'}
              signed={true}
            />
            <div className='coordinates-row__unit'>м</div>
          </div>
        </div>
        <div className='object-panel__short-input-wrapper'>
          <div className='object-panel__label'>Y</div>
          <div className='object-panel__input-wrapper'>
            <NumberInput
              value={yCoordinate?.toFixed(2)}
              disabled={!editMode}
              classNames={'with-unit'}
              setValue={setCoordinatesY}
              data-testid={'building-y-coordinate'}
              signed={true}
            />
            <div className='coordinates-row__unit'>м</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CoordinatesRow
