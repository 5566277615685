import * as React from 'react'

function IconTrash() {
  return (
    <svg className='svg' width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 1C6.44772 1 6 1.44772 6 2V3H2V4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H14V3H10V2C10 1.44772 9.55228 1 9 1H7ZM6 6H7V12H6V6ZM10 6H9V12H10V6Z'
        fill='#405264'
      />
    </svg>
  )
}

export default IconTrash
