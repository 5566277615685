import * as React from 'react'

function PenIcon() {
  return (
    <svg className='svg' width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9 2H7V7H2V9H7V14H9V9H14V7H9V2Z' fill='#838383' />
    </svg>
  )
}

export default PenIcon
