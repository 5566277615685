import React from 'react'

// @ts-ignore
import loader from '../../../../images/loader.gif'

import './style.scss'

interface IMethodWindowProps {
  methodName: string
}

const MethodWindow: React.FC<IMethodWindowProps> = ({ methodName }) => {
  return (
    <div className='method-window__background'>
      <div className='method-window__container'>
        <div className='method-window__wrapper'>
          <div className='method-window__title'>Идет расчет</div>
          <div className='method-window__method-name'>{methodName}</div>
          <img src={loader} alt='...' />
        </div>
      </div>
    </div>
  )
}

export default MethodWindow
