import React from 'react'
import NumberInput from '../../../../components/NumberInput/numberInput'
import type * as GenPlanTypes from '@numdes/genplaneditor'

interface ISizeRowProps {
  editMode: boolean
  length?: number
  width?: number
  radius?: number
  updateBuilding(building: GenPlanTypes.BuildingUpdateParams): void
}

const SizeRow: React.FC<ISizeRowProps> = ({ editMode, length, width, radius, updateBuilding }) => {
  const [lengthValue, setLengthValue] = React.useState<number>()
  const [widthValue, setWidthValue] = React.useState<number>()
  const [radiusValue, setRadiusValue] = React.useState<number>()

  React.useEffect(() => {
    setLengthValue(length)
    setWidthValue(width)
    setRadiusValue(radius)
  }, [width, length, radius])

  const setLength = (value: number) => {
    setLengthValue(value)
    updateBuilding({ length_m: value })
  }

  const setWidth = (value: number) => {
    setWidthValue(value)
    updateBuilding({ width_m: value })
  }

  const setRadius = (value: number) => {
    setRadiusValue(value)
    updateBuilding({ radius_m: value })
  }

  return (
    <div className='object-panel__size-row'>
      <div>
        {radiusValue && (
          <>
            {' '}
            <div className='object-panel__label'>Радиус</div>
            <div className='object-panel__input-wrapper'>
              <NumberInput value={radiusValue} disabled={!editMode} classNames={'with-unit'} setValue={setRadius} />
              <div className='coordinates-row__unit'>м</div>
            </div>
          </>
        )}
        {lengthValue && (
          <>
            <div className='object-panel__label'>Длина</div>
            <div className='object-panel__input-wrapper'>
              <NumberInput value={lengthValue} disabled={!editMode} classNames={'with-unit'} setValue={setLength} />
              <div className='coordinates-row__unit'>м</div>
            </div>
          </>
        )}
      </div>
      <div>
        {widthValue && (
          <>
            {' '}
            <div className='object-panel__label'>Ширина</div>
            <div className='object-panel__input-wrapper'>
              <NumberInput value={widthValue} disabled={!editMode} classNames={'with-unit'} setValue={setWidth} />
              <div className='coordinates-row__unit'>м</div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default SizeRow
