import * as React from 'react'

import classnames from 'classnames'

import './style.scss'

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: boolean
  onEnter?(): void
  classNames?: string
  children?: any
}

const Input: React.FC<IInput> = ({ error, onEnter, classNames, ...props }) => {
  const ref = React.useRef<HTMLInputElement | null>(null)
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (['Enter', 'Escape'].includes(e.key)) {
      ref?.current?.blur()
      if (['Enter'].includes(e.key) && typeof onEnter === 'function') {
        onEnter()
      }
    }
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
    }
    return false
  }

  return (
    <input ref={ref} className={classnames('input', classNames, { error })} {...props} onKeyDown={onKeyDownHandler} />
  )
}

export default Input
