import * as React from 'react'

function IconArrayDown(props: any) {
  return (
    <svg width={12} height={12} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.005 8.341L2.03 4.366l.707-.707 3.268 3.268 3.268-3.268.707.707-3.975 3.975z'
        fill='#2C2C2C'
      />
    </svg>
  )
}

export default IconArrayDown
