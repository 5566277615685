import * as React from 'react'

function CheckedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.79891 10.4172L3.12402 7.07926L1.7793 8.5597L6.99394 13.2963L14.7331 4.07305L13.201 2.78748L6.79891 10.4172Z'
        fill='#0078D2'
      />
    </svg>
  )
}

export default CheckedIcon
