import { Package } from '@numdes/genplaneditor'
//import { Package } from '../../plan-design-component/src'
import React from 'react'
import '../style.scss'
import Layout from '../../components/Layout/layout'
import ObjectItem from './components/ObjectItem/item'
import ObjectPanel from './components/ObjectPanel/objectPanel'
import BuildingType from './components/BuildingType/buildingType'
import AlgorithmPanel from './components/AlgorithmPanel/AlgorithmPanel'
import MethodWindow from './components/MethodWindow/methodWindow'
import MethodErrorWindow from './components/MethodWindow/methodErrorWindow'
import type * as GenPlanTypes from '@numdes/genplaneditor'
import { getTaskId } from './constants/project'
import { useLocation, useNavigate } from 'react-router-dom'

const ProjectPage: React.FC = () => {
  const [buildings, setBuildings] = React.useState<GenPlanTypes.Building[]>([])
  const [glossary, setGlossary] = React.useState<GenPlanTypes.GlossaryType>()
  const [object, setObject] = React.useState<GenPlanTypes.ExtendedBuilding>()
  const [activeObjectId, setActiveObjectId] = React.useState<string>()
  const [connectionPointsOptions, setConnectionPointsOptions] = React.useState<
    GenPlanTypes.ConnectionPointWithConnectionPointsToConnect[]
  >([])
  const [calculationMethodsTypes, setCalculationMethodsTypes] = React.useState<GenPlanTypes.CalculationMethod[]>([])
  const [buildingTypeIdToCreate, setBuildingTypeIdToCreate] = React.useState<string>()
  const [createConnection, setCreateConnection] = React.useState<{
    id: string
    data: GenPlanTypes.CreateConnectionData
  }>()
  const [updateConnection, setUpdateConnection] = React.useState<{
    id: string
    data: GenPlanTypes.UpdateConnectionData
  }>()
  const [deleteConnection, setDeleteConnection] = React.useState<{
    id: string
  }>()
  const [editMode, setEditMode] = React.useState<{
    edit: boolean
    status: GenPlanTypes.FinishManualModeCommandStatus
  }>({ edit: false, status: 'CANCEL' })
  const [deltaCoordinates, setDeltaCoordinates] = React.useState<{
    x: number
    y: number
  }>({ x: 0, y: 0 })
  const [coordinateError, setCoordinateError] = React.useState<boolean>(false)
  const [newBuildingParams, setNewBuildingParams] = React.useState<GenPlanTypes.BuildingUpdateParams>({})
  const [actionId, setActionId] = React.useState<string>('')
  const [taskId, setTaskId] = React.useState<string | undefined>()
  const [task, setTask] = React.useState<GenPlanTypes.ExtendedTaskType>()
  const [method, setMethod] = React.useState<GenPlanTypes.CalculationMethod>()
  const [calculationInProgress, setCalculationInProgress] = React.useState<boolean>(false)
  const [methodErrorMessage, setMethodErrorMessage] = React.useState<string>()
  const objectPanelRef = React.useRef<HTMLDivElement>(null)
  const leftPanelRef = React.useRef<HTMLDivElement>(null)
  const [connectionIdToShow, setConnectionIdToShow] = React.useState<string>('')
  const [blobObj, setBlobObj] = React.useState<string>()
  const [darkTheme, setDarkTheme] = React.useState<boolean>(false)
  const [taskIdToPublish, setTaskIdToPublish] = React.useState<string>()
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (location.state?.id) setTaskId(location.state.id)
    else setTaskId(getTaskId())
  }, [])

  React.useEffect(() => {
    if (blobObj) {
      const getFileName: { [name: string]: string } = {
        '1': 'drawing.dxf',
        '2': 'volume_values_report.xlsx',
        '3': 'project_distances_report.xlsx',
        '4': 'communication_report.xlsx',
      }
      const url = window.URL.createObjectURL(new Blob([blobObj]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', getFileName[actionId])
      document.body.appendChild(link)
      link.click()
      setBlobObj(undefined)
    }
    setActionId('')
  }, [blobObj])

  React.useEffect(() => {
    if (!calculationInProgress && method) setMethod(undefined)
  }, [calculationInProgress])

  React.useEffect(() => {
    if (task?.type && task?.type === 'BTS') navigate('/solution', { state: { id: taskId } })
  }, [task?.type])

  return (
    <div className='home-page__container'>
      {calculationInProgress ? (
        <MethodWindow methodName={method?.description || ''} />
      ) : methodErrorMessage ? (
        <MethodErrorWindow
          errorMessage={methodErrorMessage}
          clearErrorMessage={() => setMethodErrorMessage(undefined)}
        />
      ) : (
        <></>
      )}
      <Layout
        editMode={editMode.edit}
        setEditMode={setEditMode}
        setActionId={setActionId}
        taskId={taskId}
        setTaskId={setTaskId}
        darkTheme={darkTheme}
        setDarkTheme={setDarkTheme}
        taskType='PROJECT'
        task={task}
        setTaskIdToPublish={setTaskIdToPublish}
      >
        <div className='home-page__content'>
          <div className='home-page__side-panel left' ref={leftPanelRef}>
            <div className='home-page__objects-list'>
              <div className='home-page__objects-list-top-row'>
                <div className='home-page__objects-list-title'>Список сооружений</div>
                <BuildingType
                  editMode={editMode.edit}
                  buildingTypes={glossary?.building_types || []}
                  setBuildingTypeId={setBuildingTypeIdToCreate}
                />
              </div>
              {buildings.map((item, index) => (
                <ObjectItem
                  key={index}
                  id={item.id}
                  name={item.name}
                  label={item.label}
                  setActiveObjectId={setActiveObjectId}
                  activeObjectId={activeObjectId}
                />
              ))}
            </div>
            <div className='home-page__algorithm'>
              <AlgorithmPanel
                editMode={editMode.edit}
                methodsOptions={calculationMethodsTypes}
                panelRef={leftPanelRef}
                setMethod={setMethod}
              />
            </div>
          </div>
          <div className='home-page__graphic-panel'>
            <Package
              baseApiURL={process.env.REACT_APP_API_URL}
              setBuildings={setBuildings}
              activeObjectId={activeObjectId}
              setActiveObjectId={setActiveObjectId}
              editMode={editMode}
              setActiveObject={setObject}
              deltaCoordinates={deltaCoordinates}
              setGlossary={setGlossary}
              setCalculationMethods={setCalculationMethodsTypes}
              setConnectionPointsOptions={setConnectionPointsOptions}
              setCoordinatesError={setCoordinateError}
              newBuildingParams={newBuildingParams}
              buildingTypeIdToCreate={buildingTypeIdToCreate}
              resetCreateMode={() => setBuildingTypeIdToCreate(undefined)}
              createConnection={createConnection}
              updateConnection={updateConnection}
              deleteConnection={deleteConnection}
              taskId={taskId}
              setEditMode={setEditMode}
              methodName={method?.method_name}
              setCalculationInProgress={setCalculationInProgress}
              setMethodErrorMessage={setMethodErrorMessage}
              connectionIdToShow={connectionIdToShow}
              actionId={actionId}
              resetActionId={() => setActionId('')}
              setBlobObj={setBlobObj}
              darkTheme={darkTheme}
              setTask={setTask}
              setTaskId={setTaskId}
              taskIdToPublish={taskIdToPublish}
            />
          </div>
          <div className='home-page__side-panel right' ref={objectPanelRef}>
            <ObjectPanel
              object={object}
              editMode={editMode.edit}
              setDeltaCoordinates={setDeltaCoordinates}
              coordinateError={coordinateError}
              setCoordinateError={setCoordinateError}
              updateBuilding={setNewBuildingParams}
              panelRef={objectPanelRef}
              connectionTypes={glossary?.connection_types || []}
              connectionPointsOptions={connectionPointsOptions}
              setCreateConnection={setCreateConnection}
              setUpdateConnection={setUpdateConnection}
              setDeleteConnection={setDeleteConnection}
              setConnectionIdToShow={setConnectionIdToShow}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default ProjectPage
