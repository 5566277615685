import React from 'react'
import type * as GenPlanTypes from '@numdes/genplaneditor'
import './style.scss'
import ConnectionPoint from './ConnectionPoint'
import Button from '../../../../components/Button'
import classNames from 'classnames'
import IconAdd from '../../../../images/IconAdd'

interface IPointsProps {
  editMode: boolean
  object: GenPlanTypes.ExtendedBuilding | undefined
  glossary: GenPlanTypes.GlossaryType | undefined
  panelRef?: React.RefObject<HTMLDivElement>
  setCreateConnectionPoint(connectionPoint: { data: GenPlanTypes.CreateConnectionPoint }): void
  setUpdateConnectionPoint(connectionPoint: { id: string; data: GenPlanTypes.UpdateConnectionPoint }): void
  setDeleteConnectionPoint(connectionPoint: { id: string }): void
}

const Points: React.FC<IPointsProps> = ({
  editMode,
  object,
  glossary,
  panelRef,
  setUpdateConnectionPoint,
  setCreateConnectionPoint,
  setDeleteConnectionPoint,
}) => {
  const onCreateHandler = () => {
    if (glossary?.connection_point_types[0].id)
      setCreateConnectionPoint({
        data: { border: 'LEFT', offset: 0, direction: 'INPUT', type_id: glossary.connection_point_types[0].id },
      })
  }

  return (
    <>
      <div className='home-page__objects-list-top-row object-panel-top-row'>
        <div className='home-page__objects-list-title'>Интерфейсные точки</div>
        <Button className={classNames('home-page__add-object-btn')} onClick={onCreateHandler} disabled={!editMode}>
          <IconAdd />
        </Button>
      </div>
      {object ? (
        <>
          {object?.connection_points.map((item, index) => (
            <ConnectionPoint
              key={index}
              index={index}
              connectionPoint={item as GenPlanTypes.ExtendedConnectionPoint}
              editMode={editMode}
              shape={object?.radius_m ? 'CIRCLE' : 'RECT'}
              glossary={glossary}
              panelRef={panelRef}
              setUpdateConnectionPoint={setUpdateConnectionPoint}
              setDeleteConnectionPoint={setDeleteConnectionPoint}
            />
          ))}
        </>
      ) : (
        <div className='object-panel__empty-message'>{'Объект не выбран'}</div>
      )}
    </>
  )
}

export default Points
