import React from 'react'

import ActionItem from './actionItem'

interface IActionsListProps {
  actions: { id: string; name: string }[]
  setActionId(id: string): void
  closeList(): void
}

const ActionsList: React.FC<IActionsListProps> = ({ actions, setActionId, closeList }) => {
  return (
    <>
      {actions.map((item) => (
        <ActionItem key={item.id} item={item} setActionId={setActionId} closeList={closeList} />
      ))}
    </>
  )
}

export default ActionsList
