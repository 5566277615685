import React from 'react'
import GenPlanTypes from '@numdes/genplaneditor'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import IconTrash from '../../../../images/IconTrash'
import Button from '../../../../components/Button'
import NumberInput from '../../../../components/NumberInput/numberInput'
import PointPositionButton from './PointPositionButton'

interface IConnectionPointProps {
  connectionPoint: GenPlanTypes.ExtendedConnectionPoint
  index: number
  editMode: boolean
  shape: 'CIRCLE' | 'RECT'
  glossary: GenPlanTypes.GlossaryType | undefined
  panelRef?: React.RefObject<HTMLDivElement>
  setUpdateConnectionPoint(connectionPoint: { id: string; data: GenPlanTypes.UpdateConnectionPoint }): void
  setDeleteConnectionPoint(connectionPoint: { id: string }): void
}

type ConnectionPointDirectionType = {
  id: GenPlanTypes.ConnectionPointDirectionType
  name: string
}

const connectionPointDirectionOptions: ConnectionPointDirectionType[] = [
  { id: 'INPUT', name: 'Вход' },
  { id: 'OUTPUT', name: 'Выход' },
]

const ConnectionPoint: React.FC<IConnectionPointProps> = ({
  index,
  connectionPoint,
  editMode,
  shape,
  glossary,
  panelRef,
  setDeleteConnectionPoint,
  setUpdateConnectionPoint,
}) => {
  const [positionValue, setPositionValue] = React.useState<number>()
  const [directionValue, setDirectionValue] = React.useState<ConnectionPointDirectionType>()
  const [typeValue, setTypeValue] = React.useState<GenPlanTypes.ConnectionPointType>()

  React.useEffect(() => {
    setPositionValue(connectionPoint.offset)
    setTypeValue(glossary?.connection_point_types.find((item) => item.id === connectionPoint.type_id))
    setDirectionValue(connectionPointDirectionOptions.find((item) => item.id === connectionPoint.direction))
  }, [connectionPoint])

  const onChangePositionValue = (value: number) => {
    setPositionValue(value)
    setUpdateConnectionPoint({
      id: connectionPoint.id,
      data: {
        offset: value,
        border: connectionPoint.border,
      },
    })
  }

  const onChangeDirectionValue = (value: ConnectionPointDirectionType) => {
    setDirectionValue(value)
    setUpdateConnectionPoint({
      id: connectionPoint.id,
      data: {
        direction: value.id,
      },
    })
  }

  const onChangeTypeValue = (value: GenPlanTypes.ConnectionPointType) => {
    setTypeValue(value)
    setUpdateConnectionPoint({
      id: connectionPoint.id,
      data: {
        type_id: value.id,
      },
    })
  }

  const onDeleteHandler = () => {
    setDeleteConnectionPoint({ id: connectionPoint.id })
  }

  return (
    <div className='connection-point__container'>
      <div className='connection-point__top-row-container'>
        <div className='connection-point__index'>{index + 1}</div>
        <div className='connection-point__props-container'>
          <div className='connection-point__type-container'>
            <div className='object-panel__label'>Тип</div>
            <Dropdown
              options={glossary?.connection_point_types || []}
              displayedField='name'
              disabled={!editMode}
              panelRef={panelRef}
              selectedValue={typeValue}
              setSelectedValue={onChangeTypeValue}
            />
          </div>
          <div className='connection-point__type-container'>
            <div className='object-panel__label'>Направление</div>
            <Dropdown
              options={connectionPointDirectionOptions}
              displayedField='name'
              disabled={!editMode}
              panelRef={panelRef}
              selectedValue={directionValue}
              setSelectedValue={onChangeDirectionValue}
            />
          </div>
        </div>
        <div>
          <div className='object-panel__dummy-label' />
          <Button
            className='connection-point__delete-btn'
            mode='secondary'
            onClick={onDeleteHandler}
            disabled={!editMode}
          >
            <IconTrash />
          </Button>
        </div>
      </div>
      <div className='connection-point__second-row-container'>
        <div className='connection-point__props-container'>
          <div className='connection-point__type-container'>
            <div className='object-panel__label'>Расположение</div>
            <div className='icon-buttons-row'>
              <PointPositionButton
                editMode={editMode}
                shape={shape}
                connectionPoint={connectionPoint}
                active={connectionPoint.border === 'TOP'}
                btnId={'TOP'}
                setUpdateConnectionPoint={setUpdateConnectionPoint}
              />
              <PointPositionButton
                editMode={editMode}
                shape={shape}
                connectionPoint={connectionPoint}
                active={connectionPoint.border === 'RIGHT'}
                btnId={'RIGHT'}
                setUpdateConnectionPoint={setUpdateConnectionPoint}
              />
              <PointPositionButton
                editMode={editMode}
                shape={shape}
                connectionPoint={connectionPoint}
                active={connectionPoint.border === 'LEFT'}
                btnId={'LEFT'}
                setUpdateConnectionPoint={setUpdateConnectionPoint}
              />
              <PointPositionButton
                editMode={editMode}
                shape={shape}
                connectionPoint={connectionPoint}
                active={connectionPoint.border === 'BOTTOM'}
                btnId={'BOTTOM'}
                setUpdateConnectionPoint={setUpdateConnectionPoint}
              />
            </div>
          </div>
          {shape === 'RECT' && (
            <div className='connection-point__position-value-container'>
              <div className='object-panel__input-wrapper'>
                <NumberInput
                  value={positionValue}
                  disabled={!editMode}
                  classNames={'with-unit'}
                  setValue={onChangePositionValue}
                />
                <div className='coordinates-row__unit'>м</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='object-panel__separator' />
    </div>
  )
}

export default ConnectionPoint
