import React from 'react'

import './App.css'

import { Route, Routes } from 'react-router-dom'
import ProjectPage from './pages/Project/project'
import BasicSolutionPage from './pages/BasicSolution/solution'

const App: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<ProjectPage />} />
      <Route path='/solution' element={<BasicSolutionPage />} />
    </Routes>
  )
}

export default App
