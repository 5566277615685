import React from 'react'
import type * as GenPlanTypes from '@numdes/genplaneditor'
import SizeRow from './SizeRow'
import Specification from './Specification'
import './style.scss'
import ShapeRow from './ShapeRow'
import TitleRow from './TitleRow'
import TypeRow from './TypeRow'

interface IPropertiesProps {
  editMode: boolean
  object: GenPlanTypes.ExtendedBuilding | undefined
  updateBuilding(building: GenPlanTypes.BuildingUpdateParams): void
  panelRef?: React.RefObject<HTMLDivElement>
  taskName: string
  objectTypeId: string
  glossary: GenPlanTypes.GlossaryType | undefined
}

const Properties: React.FC<IPropertiesProps> = ({
  editMode,
  object,
  updateBuilding,
  panelRef,
  taskName,
  objectTypeId,
  glossary,
}) => {
  return object ? (
    <>
      <TitleRow title={taskName} editMode={editMode} />
      <TypeRow type={objectTypeId} editMode={editMode} glossary={glossary} panelRef={panelRef} />
      <div className='object-panel__separator' />
      <ShapeRow
        editMode={editMode}
        shape={object?.radius_m ? 'CIRCLE' : 'RECT'}
        updateBuilding={updateBuilding}
        length={object?.length_m}
        radius={object?.radius_m}
      />
      <div className='object-panel__separator' />
      <SizeRow
        editMode={editMode}
        updateBuilding={updateBuilding}
        length={object?.length_m}
        width={object?.width_m}
        radius={object?.radius_m}
      />
      <div className='object-panel__separator' />
      <Specification
        editMode={editMode}
        glossary={glossary}
        object={object}
        panelRef={panelRef}
        updateBuilding={updateBuilding}
      />
    </>
  ) : (
    <div className='object-panel__empty-message'>{'Объект не выбран'}</div>
  )
}

export default Properties
