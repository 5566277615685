import React from 'react'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import GenPlanTypes from '@numdes/genplaneditor'

interface ISpecificationProps {
  editMode: boolean
  panelRef?: React.RefObject<HTMLDivElement>
  glossary: GenPlanTypes.GlossaryType | undefined
  object: GenPlanTypes.ExtendedBuilding | undefined
  updateBuilding(building: GenPlanTypes.BuildingUpdateParams): void
}

const Specification: React.FC<ISpecificationProps> = ({ editMode, panelRef, glossary, object, updateBuilding }) => {
  const [fireResistanceValue, setFireResistanceValue] = React.useState<GenPlanTypes.EnumEntityType>()
  const [fireDangerValue, setFireDangerValue] = React.useState<GenPlanTypes.EnumEntityType>()
  const [structuralFireHazardClassValue, setStructuralFireHazardClassValue] =
    React.useState<GenPlanTypes.EnumEntityType>()
  const [functionalAreaValue, setFunctionalAreaValue] = React.useState<GenPlanTypes.EnumEntityType>()

  React.useEffect(() => {
    setFireResistanceValue(
      glossary?.fire_resistance.find((item) => item.value === (object as any)?.fire_resistance_rating),
    )
  }, [(object as any)?.fire_resistance_rating])

  React.useEffect(() => {
    setFireDangerValue(glossary?.fire_danger.find((item) => item.value === (object as any)?.fire_danger_rating))
  }, [(object as any)?.fire_danger_rating])

  React.useEffect(() => {
    setStructuralFireHazardClassValue(
      glossary?.structural_fire_hazard_class.find(
        (item) => item.value === (object as any)?.structural_fire_hazard_class,
      ),
    )
  }, [(object as any)?.structural_fire_hazard_class])

  React.useEffect(() => {
    setFunctionalAreaValue(glossary?.functional_area.find((item) => item.value === (object as any)?.functional_area))
  }, [(object as any)?.functional_area])

  const onChangeFireResistanceRatingValue = (value: GenPlanTypes.EnumEntityType) => {
    setFireResistanceValue(value)
    updateBuilding({ fire_resistance_rating: value.value as any })
  }

  const onChangeFireDangerRatingValue = (value: GenPlanTypes.EnumEntityType) => {
    setFireDangerValue(value)
    updateBuilding({ fire_danger_rating: value.value as any })
  }

  const onChangeStructuralFireHazardClassValue = (value: GenPlanTypes.EnumEntityType) => {
    setStructuralFireHazardClassValue(value)
    updateBuilding({ structural_fire_hazard_class: value.value as any })
  }

  const onChangeFunctionalAreaValue = (value: GenPlanTypes.EnumEntityType) => {
    setFunctionalAreaValue(value)
    updateBuilding({ functional_area: value.value as any })
  }
  return (
    <>
      <div className='specification__top-row-title'>Характеристики</div>
      <div className='specification__item'>
        <div className='object-panel__label'>Степень огнестойкости</div>
        <Dropdown
          options={glossary?.fire_resistance || []}
          displayedField='label'
          disabled={!editMode}
          panelRef={panelRef}
          selectedValue={fireResistanceValue}
          setSelectedValue={onChangeFireResistanceRatingValue}
        />
      </div>
      <div className='specification__item'>
        <div className='object-panel__label'>Категория взрывопожар. и пожарной опасности</div>
        <Dropdown
          options={glossary?.fire_danger || []}
          displayedField='label'
          disabled={!editMode}
          panelRef={panelRef}
          selectedValue={fireDangerValue}
          setSelectedValue={onChangeFireDangerRatingValue}
        />
      </div>
      <div className='specification__item'>
        <div className='object-panel__label'>Класс конструктивной пожарной опасности</div>
        <Dropdown
          options={glossary?.structural_fire_hazard_class || []}
          displayedField='label'
          disabled={!editMode}
          panelRef={panelRef}
          selectedValue={structuralFireHazardClassValue}
          setSelectedValue={onChangeStructuralFireHazardClassValue}
        />
      </div>
      <div className='specification__item'>
        <div className='object-panel__label'>Функциональная зона (по умолчанию)</div>
        <Dropdown
          options={glossary?.functional_area || []}
          displayedField='label'
          disabled={!editMode}
          panelRef={panelRef}
          selectedValue={functionalAreaValue}
          setSelectedValue={onChangeFunctionalAreaValue}
        />
      </div>
    </>
  )
}

export default Specification
