import React from 'react'
import classNames from 'classnames'

interface IActionItemProps {
  item: { id: string; name: string }
  setActionId(id: string): void
  closeList(): void
}

const ActionItem: React.FC<IActionItemProps> = ({ item, setActionId, closeList }) => {
  const optionRef = React.useRef<HTMLDivElement>(null)
  const valueRef = React.useRef<HTMLSpanElement>(null)
  const [valueClassName, setValueClassName] = React.useState<string>()

  React.useEffect(() => {
    if (optionRef.current && valueRef.current) {
      if (
        valueRef.current.offsetWidth >
        optionRef.current.clientWidth -
          parseInt(getComputedStyle(optionRef.current).paddingLeft) -
          parseInt(getComputedStyle(optionRef.current).paddingRight)
      ) {
        setValueClassName('animate')
      } else {
        setValueClassName(undefined)
      }
    }
  }, [optionRef.current, valueRef.current])

  const onItemClick = () => {
    setActionId(item.id)
    closeList()
  }
  return (
    <div className='dropdown__option' onClick={onItemClick} ref={optionRef}>
      <span className={classNames('dropdown__option-value', valueClassName)} ref={valueRef}>
        {item.name}
      </span>
    </div>
  )
}

export default ActionItem
