import React from 'react'
import Input from '../../../../components/Input'

interface ITitleRowProps {
  editMode: boolean
  title: string
}

const TitleRow: React.FC<ITitleRowProps> = ({ editMode, title }) => {
  const [titleValue, setTitleValue] = React.useState<string>('')

  React.useEffect(() => {
    setTitleValue(title)
  }, [title])

  const onTitleChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value)
  }

  const updateTitle = () => {
    setTitleValue(titleValue)
    //updateBuilding({ name: titleValue })
  }

  const resetTitle = () => {
    setTitleValue(title)
  }

  return (
    <div className={'property__item'}>
      <div className='object-panel__label'>Название</div>
      <Input
        value={titleValue}
        onChange={onTitleChangeHandler}
        onBlur={resetTitle}
        onEnter={updateTitle}
        disabled={true}
      />
    </div>
  )
}

export default TitleRow
