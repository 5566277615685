import * as React from 'react'

import './style.scss'
import Button from '../Button'
import Input from '../Input'
import IconPoints from '../../images/IconPoints'
import IconLightTheme from '../../images/IconLightTheme'
import IconDarkTheme from '../../images/IconDarkTheme'
import classNames from 'classnames'
import PopupMenuLayout from '../Dropdown/PopupMenuLayout'
import ActionsList from './ActionsList/actionsList'
import type * as GenPlanTypes from '@numdes/genplaneditor'
import Dropdown from '../Dropdown/Dropdown'
import Status from './Status'
import { components } from '../../plan-design-component/src/generated/apiTypes'

interface ILayout {
  children: React.ReactNode
  setEditMode(editMode: { edit: boolean; status: GenPlanTypes.FinishManualModeCommandStatus }): void
  editMode: boolean
  setActionId(id: string): void
  taskId: string | undefined
  setTaskId(id: string): void
  darkTheme: boolean
  setDarkTheme(value: boolean): void
  taskType: 'PROJECT' | 'SOLUTION'
  task: components['schemas']['ExtendedTask'] | undefined
  setTaskIdToPublish(id: string): void
}

const editActionsOptions = [
  { id: '1', name: 'Экспорт проекта .dxf' },
  { id: '2', name: 'Экспорт характеристик площадного объекта .xlsx' },
  { id: '3', name: 'Экспорт отчета о проектных расстояниях .xlsx' },
  { id: '4', name: 'Экспорт отчета о длине коммуникаций .xlsx' },
  { id: '5', name: 'Очистить слой надземных коммуникаций' },
  { id: '6', name: 'Очистить слой подземных коммуникаций' },
  { id: '7', name: 'Очистить слой проездов' },
]

const viewActionsOptions = [
  { id: '1', name: 'Экспорт проекта .dxf' },
  { id: '2', name: 'Экспорт характеристик площадного объекта .xlsx' },
  { id: '3', name: 'Экспорт отчета о проектных расстояниях .xlsx' },
  { id: '4', name: 'Экспорт отчета о длине коммуникаций .xlsx' },
]

const solutionOptions = [
  { id: '8', name: 'Создать копию' },
  { id: '9', name: 'Настроить чертежную сетку' },
]

const Layout: React.FC<ILayout> = ({
  children,
  editMode,
  setEditMode,
  setActionId,
  taskId,
  setTaskId,
  darkTheme,
  setDarkTheme,
  taskType,
  task,
  setTaskIdToPublish,
}) => {
  const [actionsListShown, setActionsListShown] = React.useState<boolean>(false)
  const [taskIdValue, setTaskIdValue] = React.useState<string>('')
  const controlRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (taskId) setTaskIdValue(taskId)
  }, [taskId])

  const onChangeTaskIdValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskIdValue(e.target.value)
  }

  const updateTaskId = () => {
    setTaskId(taskIdValue)
  }

  const onEditClick = () => {
    setEditMode({ edit: true, status: 'CANCEL' })
  }

  const disableEditMode = () => {
    setEditMode({ edit: false, status: 'CANCEL' })
  }

  const save = () => {
    setEditMode({ edit: false, status: 'SAVE' })
  }

  const publish = () => {
    if (taskId) setTaskIdToPublish(taskId)
  }

  return (
    <>
      <div className='layout__header'>
        <div className='layout__left-header-content'>
          {taskType === 'PROJECT' ? 'Проект #' : 'БТР #'}
          <div className='layout__left-header-content-input-wrapper'>
            <Input
              value={taskIdValue}
              onChange={onChangeTaskIdValue}
              onBlur={updateTaskId}
              onEnter={updateTaskId}
              data-testid={'task-id'}
            />
          </div>
          <div className='layout__left-header-content-dropdown-wrapper'>
            <Dropdown
              options={[]}
              displayedField='name'
              disabled={true}
              selectedValue={{ name: task?.version, id: task?.id }}
            />
          </div>
          {task?.status && (
            <div className='layout__left-header-content-status-wrapper'>
              <Status status={task?.status} />
            </div>
          )}
          {(taskType === 'PROJECT' || (taskType === 'SOLUTION' && !editMode)) && (
            <div className='layout__left-header-content-button-wrapper' ref={controlRef}>
              <Button
                className={classNames('layout-btn', actionsListShown && 'active')}
                mode='secondary'
                onClick={() => setActionsListShown(!actionsListShown)}
              >
                <IconPoints className='actions-button__points-icon' />
                Действия
              </Button>
              {actionsListShown && (
                <PopupMenuLayout
                  controlRef={controlRef}
                  closeMenu={() => setActionsListShown(false)}
                  className={classNames('dropdown__options-list', taskType.toLowerCase())}
                >
                  <ActionsList
                    actions={
                      taskType === 'SOLUTION' ? solutionOptions : editMode ? editActionsOptions : viewActionsOptions
                    }
                    setActionId={setActionId}
                    closeList={() => setActionsListShown(false)}
                  />
                </PopupMenuLayout>
              )}
            </div>
          )}
        </div>
        <div className='layout__buttons'>
          <Button className='theme-btn' mode='secondary' onClick={() => setDarkTheme(!darkTheme)}>
            {darkTheme ? <IconDarkTheme /> : <IconLightTheme />}
          </Button>
          {task?.status === 'DRAFT' && (
            <Button className={'layout-btn'} mode={'accent'} onClick={publish}>
              Опубликовать
            </Button>
          )}
          {!editMode ? (
            <Button className={'layout-btn'} onClick={onEditClick} data-testid={'edit-btn'}>
              Редактировать
            </Button>
          ) : (
            <>
              <Button className={'layout-btn'} onClick={save}>
                Сохранить
              </Button>
              <Button className={'layout-btn'} mode='secondary' onClick={disableEditMode}>
                Выйти без сохранения
              </Button>
            </>
          )}
        </div>
      </div>
      {children}
    </>
  )
}

export default Layout
